import logo from "./assets/logo.svg";
import playstore from "./assets/playstore.png";
import appstore from "./assets/appstore.png";

function App() {
  return (
    <div>
      <div className="h-screen w-screen bg-phones bg-no-repeat bg-center bg-fixed bg-cover"></div>
      {/* Main Page */}
      <div className="h-full w-full flex flex-col absolute top-0 left-0 overflow-auto">
        {/* Notifications */}
        <div className="p-2 bg-yellow-300 flex items-center justify-center sticky top-0">
          <div className="text-sm text-center flex-col flex sm:text-base sm:flex-row">
            <span className="mr-1">Notifications have been enabled.</span>
            <a
              href="https://play.google.com/store/apps/details?id=com.techstep.spike"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="underline font-bold">Update the app NOW!</span>
            </a>
          </div>
        </div>

        {/* Content */}
        <div className="mx-8 sm:mx-28 mt-12 xl:mt-20 flex-col flex items-start">
          <img src={logo} alt="Spike Logo" className="h-1/5" />
          <div className="text-xl sm:text-3xl xl:text-5xl text-white mt-10 xl:mt-16 space-y-1 sm:space-y-2 md:space-y-4">
            <p>Are you ready to witness the</p>
            <p>
              <span className="text-yellow-300 font-bold">Best</span> Community
              App for
            </p>
            <p>Musicians?</p>
          </div>
          <div className="mt-10 xl:mt-16 flex space-x-4 sm:space-x-6 w-3/4">
            <a
              href="https://play.google.com/store/apps/details?id=com.techstep.spike"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={playstore}
                alt="Play Store"
                className="cursor-pointer"
              />
            </a>
            <div>
              <img src={appstore} alt="App Store" className="cursor-pointer" />
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="p-2 pb-8 px-8 text-xs sm:text-base sm:px-28 flex flex-col items-start justify-start mt-auto space-y-2">
          <div className="flex space-x-4 sm:space-x-6 font-bold text-white">
            <a
              href="https://www.instagram.com/spikeapp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="transition duration-300 ease-in-out hover:text-yellow-300">Instagram</p>
            </a>
            <a
              href="https://www.linkedin.com/company/spike-app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="transition duration-300 ease-in-out hover:text-yellow-300">LinkedIn</p>
            </a>
            <a
              href="https://twitter.com/spike_app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="transition duration-300 ease-in-out hover:text-yellow-300">Twitter</p>
            </a>
          </div>
          <div className="flex space-x-4 sm:space-x-6 text-white">
            <a
              href="mailto: hello@spikeapp.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="transition duration-300 ease-in-out hover:text-yellow-300">hello@spikeapp.in</p>
            </a>
            <p>2021 Spike App</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
